<template>
  <div>
    <div id="header">
      <div class="d-flex flex-row justify-content-around logo">
        <img
          src="@/assets/skl-logo.png"
          alt="Smiley face"
          height="27"
          width="70"
          class="mb-2"
        />
      </div>
      <div id="header-title" class="text-center title">
        <b>ตรวจสอบสถานะคำขอเช่าซื้อออนไลน์</b>
      </div>
      <!-- <div id="popup">
        <b-icon
          icon="x-lg"
          @click.prevent="closeWindowDealer()"
          class="close-window"
        ></b-icon>
        <iframe id="popupiframe"></iframe>
      </div>
      <div id="popupdarkbg"></div> -->
    </div>
    <div class="body">
      <div v-if="page == 1" class="w-80 h-100 d-flex flex-column">
        <InputTextBox
          id="input-cardID"
          v-model="form.cardID"
          placeholder="หมายเลขบัตรประจำตัวประชาชน"
          type="number"
          pattern="[0-9]*"
          inputmode="numeric"
          inputChecker="number"
          required
          :isError="inputState.cardID.isError"
          textFloat="หมายเลขบัตรประจำตัวประชาชน"
        />
        <div
          :style="{
            display: 'block',
            'margin-left': 'auto',
            'margin-right': 'auto',
            // width: '80vw'
          }"
          id="missing-input-error"
          class="text-center w-100"
          v-if="inputState.cardID.isError"
        >
          <font color="red">{{ inputState.cardID.errorMessage }}<br /></font>
        </div>
        <b-button
          id="submit-cardID"
          variant="submit"
          @click="submitCardID"
          :disabled="buttonLoading"
          class="btn-submit-form"
        >
          <b-spinner small v-if="buttonLoading"></b-spinner>
          <span v-else>ตรวจสอบ</span>
        </b-button>
      </div>
      <div v-if="page == 2" class="min-h-100 d-flex flex-column">
        <div class="text-center">
          กรุณาเลือกรุ่นรถ / เลขที่คำขอเช่าซื้อ <br />ที่ท่านต้องการตรวจสอบ
        </div>
        <div
          v-if="dataList.length == 0"
          class="mt-4"
          :style="{ 'text-align': 'center' }"
        >
          ไม่พบคำขอเช่าซื้อ
        </div>

        <template>
          <div
            v-for="(data, index) in dataList"
            :key="index"
            class="mt-3 d-flex justify-content-between"
            @click="
              () => {
                form.selectApplication = index;
              }
            "
          >
            <img
              v-if="data.photo == ''"
              src="@/assets/images/car-dummy.png"
              height="52"
              width="67"
              class="my-auto"
            />
            <img
              v-else
              :src="data.photo"
              height="67"
              width="67"
              class="my-auto"
            />
            <svg
              class="align-self-center mx-3"
              xmlns="http://www.w3.org/2000/svg"
              width="28"
              height="28"
              viewBox="0 0 28 28"
            >
              <g
                id="Ellipse_414"
                data-name="Ellipse 414"
                fill="#fff"
                stroke="#ccc"
                stroke-width="1"
              >
                <circle cx="14" cy="14" r="14" stroke="none" />
                <circle cx="14" cy="14" r="13.5" fill="none" />
              </g>
              <template v-if="form.selectApplication == index">
                <circle
                  id="Ellipse_415"
                  data-name="Ellipse 415"
                  cx="9"
                  cy="9"
                  r="9"
                  transform="translate(5 5)"
                  fill="#46a7d6"
                />
              </template>
            </svg>
            <div class="align-self-center">
              <div v-if="data.loanTypeName == 'PL'">
                <font class="mr-5" color="#FFFFFF"></font>
                สินเชื่อเติมฝัน
              </div>
              <div v-else-if="data.loanTypeName == 'TL'">
                <font class="mr-5" color="#FFFFFF"></font>
                สินเชื่อรถเติมสุข
              </div>
              <div v-else>
                <font class="mr-1" color="#CCCCCC">รุ่นรถ:</font>
                {{data.modelCode}}
              </div>
              <font class="mr-1" color="#CCCCCC">เลขคำขอเช่าซื้อ:</font>
              {{ data.appNoShow }}<br />
              <font class="mr-1" color="#CCCCCC">ร้านค้า:</font>
              {{ data.dealerName }}
            </div>
          </div>
        </template>
        <b-button
          v-if="dataList.length == 0"
          id="submit-application"
          variant="submit"
          class="btn-submit-form"
          @click="
            () => {
              page = 1;
            }
          "
          >ย้อนกลับ</b-button
        >
        <template v-if="dataList.length != 0 && form.selectApplication != null">
          <b-form-checkbox
            :style="{
              display: 'block',
              'margin-top': 'auto',
              'margin-left': 'auto',
              'margin-right': 'auto',
              'margin-bottom': '10px',
              'font-size': '0.625rem',
              'line-height': '1.3'
            }"
            v-if="consentState.isShow"
            v-model="consentState.isCheck"
            class="pt-3"
          >
            ข้าพเจ้ารับทราบว่า ข้าพเจ้าแจ้งความประสงค์สอบถามข้อมูล ส่งคำถามถึง
            บริษัท สยามคูโบต้า ลีสซิ่ง จำกัด ผ่านช่องทางอิเล็กทรอนิกส์
            ข้าพเจ้าได้อ่าน<a
              id="redirectPrivacyPolicy"
              href="https://skl.co.th/privacy_policy"
              class="f-orange"
              ><u>นโยบายคุ้มครองส่วนบุคคล</u></a
            >
            และยอมรับ<a
              id="redirectTerms"
              href="https://www.skl.co.th/terms"
              class="f-orange"
              ><u>ข้อกำหนดและเงื่อนไขการใช้งาน</u></a
            >
            ของบริษัทฯ แล้ว และรับทราบว่าบริษัทฯ
            จะใช้หรือเปิดเผยข้อมูลข้างต้นให้แก่สยามคูโบต้าคอร์ปอเรชั่นและผู้แทนจำหน่ายสยามคูโบต้า
            ในการติดต่อข้าพเจ้าเพื่อจัดการคำขอและข้อสอบถามต่างๆตามที่ข้าพเจ้าแจ้งความประสงค์นี้
          </b-form-checkbox>
          <b-button
            :style="{
              display: 'block',
              'margin-top': '0px',
              'margin-left': 'auto',
              'margin-right': 'auto',
              'margin-bottom': '50px',
              width: '80vw',
              'max-width': '334px'
            }"
            id="submit-application"
            variant="submit"
            @click="submitApplication()"
            :disabled="
              buttonLoading || (consentState.isShow && !consentState.isCheck)
            "
          >
            <b-spinner small v-if="buttonLoading"></b-spinner>
            <span v-else>ตรวจสอบ</span>
          </b-button>
        </template>
      </div>
      <div v-if="page == 3" class="h-100 d-flex flex-column">
        <FormOTP
          id="put-OTP"
          v-model="form.otpNumber"
          placeholder="ํOTP"
          type="number"
          pattern="\d*"
          inputmode="numeric"
          :isError="inputState.otp.isError"
          :MobileNumber="applicationDetail.MobileNumber"
          :prefix="inputState.otp.prefix"
          @sendOTPAgain="requestOTP"
        />
        <div
          :style="{
            display: 'block',
            'margin-left': 'auto',
            'margin-right': 'auto',
            width: '80vw'
          }"
          id="missing-input-error"
          class="text-center"
          v-if="inputState.otp.isError"
        >
          <font color="red">{{ inputState.otp.errorMessage }}<br /></font>
        </div>
        <b-button
          :style="{
            'min-height': '50px'
          }"
          id="submit-application"
          class="btn-submit-form"
          variant="submit"
          @click="sendOTP"
          :disabled="buttonLoading"
        >
          <b-spinner small v-if="buttonLoading"></b-spinner>
          <span v-else>ยืนยัน</span>
        </b-button>
      </div>
      <div
        v-if="page == 4 && page_4_state.dataReady"
        class="h-100 d-flex flex-column"
      >
        <template v-if="status == 1">
          <Progress
            :value="mapProgrssIndex[page_4_state.data.appStatusName]"
            :style="{
              display: 'block',
              'margin-left': 'auto',
              'margin-right': 'auto',
              width: '80vw',
              'max-width': '334px'
            }"
          />
        </template>
        <b-container class="mt-3">
          <b-row>
            <b-col>
              เลขที่คำขอเช่าซื้อ
            </b-col>
            <b-col :style="{ width: '40%' }">
              {{ page_4_state.data.appNoShow }}
            </b-col>
          </b-row>
          <b-row v-if="page_4_state.data.loanTypeName == 'PL'">
            <b-col offset="6">
              สินเชื่อเติมฝัน
            </b-col>
          </b-row>
          <b-row v-else-if="page_4_state.data.loanTypeName == 'TL'">
            <b-col offset="6">
              สินเชื่อรถเติมสุข
            </b-col>
          </b-row>
          <b-row v-else>
            <b-col>รุ่นรถ</b-col>
            <b-col :style="{ width: '40%' }">
              {{ page_4_state.data.modelCode }}
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              สถานะการขอสินเชื่อ
            </b-col>
            <b-col :style="{ width: '40%' }">
              <p :style="{ color: '#F47306' }">
                {{ page_4_state.data.resultStatus }}
              </p>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              ข้อมูล ณ วันที่
            </b-col>
            <b-col :style="{ width: '40%' }">
              <p :style="{ color: '#808080' }">
                {{
                  `${page_4_state.data.asOfDate.split("-")[0]}/${
                    page_4_state.data.asOfDate.split("-")[1]
                  }/${parseInt(page_4_state.data.asOfDate.split("-")[2]) + 543}`
                }}
              </p>
            </b-col>
          </b-row>
        </b-container>
        <b-container class="my-3" v-if="showDetail">
          <template v-if="appclitionDetailHaveSub">
            <b-row>
              <b-col>
                เลขที่คำขอเช่าซื้อ
              </b-col>
              <b-col :style="{ width: '40%' }">
                {{ page_4_state.data.appNoShow }}
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                ชื่อ - สกุล
              </b-col>
              <b-col :style="{ width: '40%' }">
                {{ page_4_state.data.customerName }}
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                ข้อมูล ณ วันที่
              </b-col>
              <b-col :style="{ width: '40%' }">
                {{
                  `${page_4_state.data.asOfDate.split("-")[0]}/${
                    page_4_state.data.asOfDate.split("-")[1]
                  }/${parseInt(page_4_state.data.asOfDate.split("-")[2]) + 543}`
                }}
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                สถานะการขอสินเชื่อ
              </b-col>
              <b-col :style="{ width: '40%' }">
                <p :style="{ color: true ? '#F47306' : '#FF0000' }">
                  {{ page_4_state.data.resultStatus }}
                </p>
              </b-col>
            </b-row>
          </template>
          <template v-else>
            <div
              :style="{
                display: 'block',
                'margin-left': 'auto',
                'margin-right': 'auto',
                width: '80vw',
                'max-width': '334px'
              }"
              id="missing-input-error"
              class="text-center"
            >
              <font>
                สอบถามรายละเอียดการประเมินสินเชื่อ
                <br />ได้ที่ร้านผู้แทนจำหน่ายสยามคูโบต้า<br />ที่ท่านยื่นคำขอเช่าซื้อไว้<br />
              </font>
              <a
                :href="page_4_state.data.dealerUrl + '#footer'"
                class="f-green"
                target="_blank"
              >
                <u>ค้นหาเบอร์โทรศัพท์ติดต่อ</u>
              </a>
            </div>
          </template>
        </b-container>
        <div
          v-if="mapProgrssIndex[page_4_state.data.appStatusName] == 'COMPLETE'"
          @click="
            () => {
              showDetail = !showDetail;
            }
          "
        >
          <svg
            v-if="!showDetail"
            :style="{
              display: 'block',
              'margin-left': 'auto',
              'margin-right': 'auto',
              'margin-bottom': '20px',
              width: '80vw',
              'max-width': '334px'
            }"
            xmlns="http://www.w3.org/2000/svg"
            width="324"
            height="21"
            viewBox="0 0 324 21"
          >
            <path
              id="Path_9070"
              data-name="Path 9070"
              d="M0,0H90"
              transform="translate(0 10.5)"
              fill="none"
              stroke="#80c141"
              stroke-width="1"
            />
            <line
              id="Line_39"
              data-name="Line 39"
              x2="90"
              transform="translate(234 10.5)"
              fill="none"
              stroke="#80c141"
              stroke-width="1"
            />
            <text
              id="ดูรายละเอียดเพิ่มเติม"
              transform="translate(102.5 15)"
              fill="#80c141"
              font-size="14"
              font-family="Kanit-Medium, Kanit"
              font-weight="500"
            >
              <tspan x="0" y="0">ดูรายละเอียดเพิ่มเติม</tspan>
            </text>
          </svg>
          <svg
            v-else
            :style="{
              display: 'block',
              'margin-left': 'auto',
              'margin-right': 'auto',
              'margin-bottom': '20px',
              width: '80vw',
              'max-width': '334px'
            }"
            xmlns="http://www.w3.org/2000/svg"
            width="323"
            height="18.158"
            viewBox="0 0 323 18.158"
          >
            <line
              id="Line_40"
              data-name="Line 40"
              x2="133"
              transform="translate(0 13.012)"
              fill="none"
              stroke="#80c141"
              stroke-width="1"
            />
            <line
              id="Line_41"
              data-name="Line 41"
              x2="133"
              transform="translate(190 13.012)"
              fill="none"
              stroke="#80c141"
              stroke-width="1"
            />
            <path
              id="Path_8937"
              data-name="Path 8937"
              d="M-18655.275-11135.593l10.977-9.861,10.684,9.861"
              transform="translate(18806.145 11149.512)"
              fill="none"
              stroke="#dae0d4"
              stroke-linecap="square"
              stroke-width="6"
            />
          </svg>
        </div>
        <b-button
          :style="{
            display: 'block',
            'margin-top': 'auto',
            'margin-left': 'auto',
            'margin-right': 'auto',
            'margin-bottom': '20px',
            width: '80vw !important',
            'max-width': '334px'
          }"
          id="submit-application"
          variant="submit"
          @click="closeWindow"
          >ตกลง</b-button
        >
        <div
          :style="{
            display: 'block',
            'margin-left': 'auto',
            'margin-right': 'auto',
            width: '80vw',
            'max-width': '334px'
          }"
          id="missing-input-error"
          class="text-center"
          v-if="
            appclitionDetailHaveSub ||
              mapProgrssIndex[page_4_state.data.appStatusName] != 'COMPLETE'
          "
        >
          <font>
            สอบถามรายละเอียดการประเมินสินเชื่อ
            <br />ได้ที่ร้านผู้แทนจำหน่ายสยามคูโบต้า<br />ที่ท่านยื่นคำขอเช่าซื้อไว้<br />
          </font>
          <a
            v-if="page_4_state.data.dealerUrl"
            :href="page_4_state.data.dealerUrl + '#footer'"
            target="_blank"
            class="f-green"
          >
            <u>ค้นหาเบอร์โทรศัพท์ติดต่อ</u>
          </a>
          <p v-else @click="openModalRental" class="f-green">
            <u>ค้นหาเบอร์โทรศัพท์ติดต่อ</u>
          </p>
        </div>
      </div>
      <div
        v-else-if="page == 4 && !page_4_state.dataReady"
        :style="{
          height: '100%',
          width: '100%',
          display: 'flex',
          'justify-content': 'center',
          'align-items': 'center'
        }"
      >
        กำลังโหลด...
      </div>
      <div v-else-if="page == 5">
        <PageResult :data="data" />
      </div>
    </div>
    <!-- <PolicyModal ref="policyModal" @Accept="submitPolicy" /> -->
    <ModalRental />
  </div>
</template>

<script>
// 1000006756
import Progress from "./Components/progress";
const InputTextBox = () => import("@/components/inputs/InputTextBox-New");
const FormOTP = () => import("@/components/panels/formOTP");
// const PolicyModal = () => import("@/components/Policy");
const ModalRental = () => import("./Components/ModalRental.vue");

const mapProgrssIndex = {
  "WAITING CONFIRM APPLICATION": "SKL ได้รับคำขอเช่าซื้อจากท่านเรียบร้อยแล้ว",
  ASSESSSESMENT: "อยู่ระหว่างการตรวจสอบและเข้าประเมินสินเชื่อ",
  EDIT: "อยู่ระหว่างดำเนินการพิจารณาสินเชื่อ",
  WAIT: "อยู่ระหว่างดำเนินการพิจารณาสินเชื่อ",
  COMPLETE: "คำขอสินเชื่อของท่านได้รับผลประเมินแล้ว"
};

export default {
  components: {
    Progress,
    InputTextBox,
    FormOTP,
    // PolicyModal,
    ModalRental
  },
  data() {
    return {
      lineProfile: {},
      checked: false,
      dataReady: true,
      buttonLoading: false,
      page: 1,
      status: 1,
      showDetail: false,
      form: {
        cardID: "", // 3240700012616
        // 3650400826665
        selectApplication: null,
        otpNumber: ""
      },
      dataList: [],
      applicationDetail: {
        MobileNumber: ""
      },
      appclitionDetailHaveSub: true,
      mapProgrssIndex: {
        "WAITING CONFIRM APPLICATION": 1,
        ASSESSMENT: 2,
        EDIT: 3,
        WAIT: 3,
        COMPLETE: 4
      },
      inputState: {
        cardID: {
          isError: false,
          errorMessage: ""
        },
        otp: {
          isError: false,
          prefix: "",
          errorMessage: ""
        }
      },
      page_4_state: {
        dataReady: false,
        data: {}
      },
      consentState: {
        isShow: true,
        isCheck: false
      }
    };
  },

  watch: {
    page: {
      handler(val) {
        if (val == 4) {
          this.getAppDetail();
        }
      }
    }
  },
  beforeCreate() {
    this.$liff
      .init({ liffId: this.$liff_ID_RentalRequestStatus })
      .then(() => {
        if (!this.$liff.isLoggedIn()) {
          this.$liff.login().then(() => {
            this.$liff.getProfile().then(profile => {
              this.lineProfile = profile;
              this.checkTerm(profile.userId);
              // this.checkConsent();
            });
          });
        } else {
          this.$liff.getProfile().then(profile => {
            this.lineProfile = profile;
            this.checkTerm(profile.userId);
            // this.checkConsent();
          });
        }
        this.$liff.getFriendship().then(data => {
          if (!data.friendFlag) {
            this.$liff.openWindow({
              url: `https://line.me/R/ti/p/${this.$liff_Channel_ID}`,
              external: false
            });
            this.$liff.closeWindow();
          }
        });
      })
      .catch(error => {
        console.error(error);
      });
  },

  methods: {
    // clickWindow(url) {
    //   document.getElementById("popupdarkbg").style.display = "block";
    //   document.getElementById("popup").style.display = "block";
    //   document.getElementById("popupiframe").src = url;
    //   document.getElementById("popupdarkbg").onclick = function() {
    //     document.getElementById("popup").style.display = "none";
    //     document.getElementById("popupdarkbg").style.display = "none";
    //   };
    //   return false;
    // },
    // closeWindowDealer() {
    //   document.getElementById("popup").style.display = "none";
    //   document.getElementById("popupdarkbg").style.display = "none";
    // },
    openModalRental() {
      this.$bvModal.show("modal-rental");
    },
    checkTerm(lineId) {
      let myHeaders = new Headers();
      myHeaders.append("API-KEY", this.$API_KEY_DEV_LINE_SKL);
      myHeaders.append("Content-Type", "application/json");
      let requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
      };
      fetch(
        `${this.$API_DEV_LINE_SKL}/customer/checkterms/${lineId}`,
        requestOptions
      )
        .then(response => response.json())
        .then(result => {
          if (result.result == 1) {
            if (!result.detail) {
              this.$router.push({
                path: "/policy",
                query: {
                  lineid: lineId,
                  term: true
                }
              });
              // let routeData = this.$router.resolve({
              //   name: "policy",
              //   query: { lineid: lineId }
              // });
              // window.open(routeData.href, "_blank");
            }
          } else {
            // let routeData = this.$router.resolve({
            //   name: "policy",
            //   query: { lineid: lineId }
            // });
            // window.open(routeData.href, "_blank");
            this.$router.push({
              path: "/policy",
              query: {
                lineid: lineId,
                term: true
              }
            });
          }
        })
        .catch(error => {
          console.error(error);
        });
    },
    createConsentLinkEvent() {
      if (this.$liff.isInClient()) {
        var loop = setInterval(() => {
          if (
            document.getElementById("redirectPrivacyPolicy") &&
            document.getElementById("redirectTerms")
          ) {
            var reP = document.getElementById("redirectPrivacyPolicy");
            reP.onclick = () => {
              this.$liff.openWindow({
                url: "https://skl.co.th/privacy_policy",
                external: false
              });
              return false;
            };
            var reT = document.getElementById("redirectTerms");
            reT.onclick = () => {
              this.$liff.openWindow({
                url: "https://www.skl.co.th/terms",
                external: false
              });
              return false;
            };
            clearInterval(loop);
          }
        }, 300);
      }
    },
    closeWindow() {
      this.$liff.closeWindow();
    },
    getAppDetail() {
      this.page_4_state.dataReady = false;
      var myHeaders = new Headers();
      myHeaders.append("API-KEY", "S1112LIN05!");
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        lineId: this.lineProfile.userId,
        IDCard: this.form.cardID,
        appNo: this.dataList[this.form.selectApplication].appNoShow
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      fetch(`${this.$API_DEV_LINE_SKL}/applyAppNo`, requestOptions)
        .then(response => response.json())
        .then(result => {
          console.log(result);
          if (result.result == 1 && result.detail.result) {
            if (result.detail.data.SubStatus == "") {
              this.appclitionDetailHaveSub = false;
            } else {
              this.appclitionDetailHaveSub = true;
            }
            this.page_4_state.data = result.detail.data;
            this.page_4_state.dataReady = true;
          }
        })
        .catch(error => {
          console.log("error", error);
        });
    },
    checkConsent() {
      // this.page = 4;
      // return;
      this.buttonLoading = true;
      var requestOptions = {
        method: "GET",
        redirect: "follow"
      };

      fetch(
        `${this.$API_DEV_LINE_SKL}/customer/consent/${this.lineProfile.userId}`,
        requestOptions
      )
        .then(response => response.json())
        .then(result => {
          if (result.result == 1) {
            // this.$refs.policyModal.content = result.detail.termsAndConditions;
            if (result.detail.consent) {
              ////
              // this.submitApplication();
              ////
              this.buttonLoading = false;
            } else {
              // this.$bvModal.show("modal-policy");
              // this.buttonLoading = false;
              this.consentState.isShow = true;
              setTimeout(() => {
                this.createConsentLinkEvent();
              }, 0);
              this.buttonLoading = false;
            }
          } else {
            this.$bvModal.show("modal-tryagain");
            this.buttonLoading = false;
          }
        })
        .catch(error => {
          console.log("error", error);
          this.$bvModal.show("modal-tryagain");
          this.buttonLoading = false;
        });
    },
    submitPolicy() {
      // this.buttonLoading = true;
      var requestOptions = {
        method: "POST",
        redirect: "follow"
      };

      fetch(
        `${this.$API_DEV_LINE_SKL}/customer/consent/${this.lineProfile.userId}`,
        requestOptions
      )
        .then(response => response.json())
        .then(result => {
          // this.$refs.policyModal.buttonLoading = false;
          this.$bvModal.hide("modal-policy");
          if (result.result == 1) {
            ////
            // this.submitApplication();
            ////
          } else {
            this.$bvModal.show("modal-tryagain");

            // this.buttonLoading = false;
          }
        })
        .catch(error => {
          console.log("error", error);
          // this.$bvModal.show("modal-tryagain");
          // this.$bvModal.hide("modal-policy");
          // this.buttonLoading = false;
        });
    },
    submitCardID() {
      if (this.form.cardID == "") {
        this.inputState.cardID.isError = true;
        return;
      }
      this.buttonLoading = true;
      this.inputState.cardID.isError = false;
      var myHeaders = new Headers();
      myHeaders.append("API-KEY", "S1112LIN05!");
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({ IDCard: this.form.cardID });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      fetch(`${this.$API_DEV_LINE_SKL}/apply`, requestOptions)
        .then(response => response.json())
        .then(result => {
          console.log(result);
          this.buttonLoading = false;
          if (result.result == 1) {
            console.log("result: " + JSON.stringify(result.detail[0]));
            if (result.detail) {
              this.dataList = result.detail;
              this.page = 2;
            } else {
              this.inputState.cardID.errorMessage =
                "ไม่มีข้อมูลในระบบกรุณาลองใหม่อีกครั้ง";
              this.inputState.cardID.isError = true;
            }
          } else {
            this.inputState.cardID.errorMessage = result.message
            this.inputState.cardID.isError = true;
          }
        })
        .catch(error => console.warn("error", error));
    },
    submitApplication() {
      // if (this.consentState.isShow && this.consentState.isCheck) {
      //   this.submitPolicy();
      // }
      this.applicationDetail.MobileNumber = this.$OverrideMobileNumber
        ? this.$OverrideMobileNumber
        : this.dataList[this.form.selectApplication].contactNo;
      // "0941496582";
      this.requestOTP();
      this.page = 3;
    },
    requestOTP() {
      var myHeaders = new Headers();
      myHeaders.append("API-KEY", "S1112LIN05!");
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        MobileNumber: `66${this.applicationDetail.MobileNumber.slice(1)}`,
        OTP: "",
        IdCard: this.form.cardID,
        AppNo: this.dataList[this.form.selectApplication].appNoShow,
        LineId: this.lineProfile.userId
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      fetch(`${this.$API_DEV_LINE_SKL}/OTPCheckApp`, requestOptions)
        .then(response => response.json())
        .then(result => {
          console.log(result);
          this.buttonLoading = false;
          if (result.result == 1) {
            this.inputState.otp.prefix = result.detail.result;
          }
        })
        .catch(error => {
          console.warn("error", error);
          this.buttonLoading = false;
        });
    },
    sendOTP() {
      this.buttonLoading = true;
      var myHeaders = new Headers();
      myHeaders.append("API-KEY", "S1112LIN05!");
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        MobileNumber: `66${this.applicationDetail.MobileNumber.slice(1)}`,
        OTP: this.form.otpNumber,
        IdCard: this.form.cardID,
        AppNo: this.dataList[this.form.selectApplication].appNoShow,
        LineId: this.lineProfile.userId
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      fetch(`${this.$API_DEV_LINE_SKL}/ValidateOTPCheckApp`, requestOptions)
        .then(response => response.json())
        .then(result => {
          console.log(result);
          this.buttonLoading = false;
          if (result.result == 1) {
            this.page = 4;
          } else {
            this.inputState.otp.isError = true;
            this.inputState.otp.errorMessage = result.message;
          }
        })
        .catch(error => {
          console.log("error", error);
          this.buttonLoading = false;
        });
    }
  }
};
</script>

<style scoped>
@media only screen and (max-width: 420px) {
  body {
    font-size: 4vw;
  }
}
.container {
  padding-right: 4%;
  padding-left: 4%;
}
.body {
  height: calc(100vh - 90px);
}
.holder {
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  font-size: 14px;
}
.item {
  width: 20%;
  border: 1px solid #000;
  height: auto;
  flex: 80px 1;
  padding-left: 5px;
  padding-right: 5px;
  overflow-wrap: anywhere;
}
#popup {
  display: none;
  position: fixed;
  top: 12%;
  left: 15%;
  width: 70%;
  height: 70%;
  background-color: white;
  z-index: 10;
}
#popup iframe {
  width: 100%;
  height: 100%;
  border: 0;
}
#popupdarkbg {
  position: fixed;
  z-index: 5;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.75);
  display: none;
}
.close-window {
  float: right;
  margin: 2px;
}
.f-green:hover {
  color: #80c141;
}
.btn-submit-form {
  display: block;
  margin-top: 0px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 50px;
  width: 80vw;
  max-width: 334px;
}
</style>
